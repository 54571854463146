import React, { useCallback, useMemo, useRef, useState } from 'react';
import colors from '@/utils/colors';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';
// import TextInput from '../TextInput';
import { Dialog, DialogTrigger, Popover } from 'react-aria-components';
import HomeaglowCalendar from '../Calendar/Calendar';
import clsx from 'clsx';
import Icon from '../icon';
import styles from './index.module.css';
import { getTimezone } from '@/utils/helper';

dayjs.extend(utc);
dayjs.extend(tz);

interface DatepickerProps {
  value?: Date;
  // eslint-disable-next-line no-unused-vars
  onChange: (param: Date) => void;
  label?: string;
  disabled?: boolean;
  min?: Date;
  max?: Date;
  visuallyHiddenLabel?: boolean;
  format?: string;
  error?: string;
}

const Datepicker = (props: DatepickerProps) => {
  const {
    value,
    onChange,
    label,
    disabled = false,
    min,
    max,
    visuallyHiddenLabel,
    error,
  } = props;

  const [isOpen, setOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const triggerRef = useRef(null);

  const id = useMemo(
    () => 'date-input-' + Math.random().toString(36).substring(7),
    [],
  );

  const dateFormat = props.format ? props.format : 'dddd, MMM D';

  const onDateChange = useCallback(
    (date: Date) => {
      onChange(date);
      setOpen(false);
    },
    [onChange, setOpen],
  );

  return (
    <>
      <DialogTrigger>
        <div
          className="relative"
          ref={triggerRef}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <div className="w-full relative">
            <input
              id={id}
              className={clsx(
                styles.input,
                'w-full h-[60px]  rounded-lg px-1 pl-[56px]',
                isHovered ? 'bg-lightGray' : 'bg-lightestGray',
                'focus:ring-1 focus:ring-purple',
                error ? 'border-2 border-darkerRed' : 'border-none',
                value && label && !visuallyHiddenLabel && 'pt-6',
              )}
              placeholder=" "
              readOnly
              type="text"
              name="date-input"
              value={
                value
                  ? dayjs(value).tz(getTimezone()).format(dateFormat)
                  : undefined
              }
              disabled={disabled}
              onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  setOpen(true);
                  e.preventDefault();
                }
              }}
            />
            {label ? (
              <label
                htmlFor={id}
                className={clsx(
                  styles.label,
                  error ? ' text-darkerRed' : ' text-darkGray',
                  '!pl-12',
                  visuallyHiddenLabel && 'sr-only',
                )}
              >
                {label}
              </label>
            ) : null}

            <div className="pointer-events-none absolute left-4 top-[18px]">
              <Icon name="calendar" size={24} color={colors.brand.gray.dark} />
            </div>
          </div>

          {error && (
            <p className="mt-1 text-darkerRed text-sm font-medium">{error}</p>
          )}

          {/* covering div, blocking input click and handling onclick to open cal */}
          <div
            className="w-full absolute top-0 left-0 right-0 bottom-0"
            onClick={() => setOpen(true)}
          />
        </div>
        <Popover
          placement="bottom start"
          triggerRef={triggerRef}
          isOpen={isOpen}
          onOpenChange={setOpen}
          className={
            'shadow-md rounded-lg border border-lightGray overflow-hidden'
          }
        >
          <Dialog className="bg-white p-1 rounded-lg">
            <div
              onClick={(e) => {
                e.preventDefault();
              }}
            >
              <HomeaglowCalendar
                value={value}
                onChange={onDateChange}
                minValue={min}
                maxValue={max}
              />
            </div>
          </Dialog>
        </Popover>
      </DialogTrigger>
    </>
  );
};

export default Datepicker;
