import { getTimezone } from '@/utils/helper';
import { useCallback } from 'react';
import type {
  CalendarCellRenderProps,
  CalendarProps as AriaCalendarProps,
  DateValue,
} from 'react-aria-components';
import { fromDate } from '@internationalized/date';

import {
  Button,
  Calendar,
  CalendarCell,
  CalendarGrid,
  CalendarGridBody,
  CalendarGridHeader as AriaCalendarGridHeader,
  CalendarHeaderCell,
  Heading,
} from 'react-aria-components';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';
import { tisaSans } from '@/pages/_app.page';

const styles = {
  base: 'outline outline-purple outline-offset-2 w-9 h-9 text-sm cursor-default rounded-full flex items-center justify-center',
  isFocusVisible: {
    false: 'outline-0',
    true: 'outline-2',
  },
  isSelected: {
    false: 'text-darkerGray hover:bg-lightestGray pressed:bg-lightestGray',
    true: 'bg-purple invalid:bg-red text-white',
  },
  isDisabled: {
    true: 'text-gray',
  },
};

const cellStyles = ({
  isSelected,
  isDisabled,
  isFocusVisible,
}: CalendarCellRenderProps) => {
  return `${styles.base} ${
    isSelected ? styles.isSelected.true : styles.isSelected.false
  } ${isDisabled ? styles.isDisabled.true : ''} ${
    isFocusVisible ? styles.isFocusVisible.true : styles.isFocusVisible.false
  }`.trim();
};

interface CalendarProps<T extends DateValue>
  extends Omit<
    AriaCalendarProps<T>,
    | 'visibleDuration'
    | 'minValue'
    | 'maxValue'
    | 'value'
    | 'onChange'
    | 'defaultValue'
  > {
  minValue?: Date;
  maxValue?: Date;
  value?: Date;
  onChange: (_date: Date) => void;
}

export default function HomeaglowCalendar<T extends DateValue>(
  props: CalendarProps<T>,
) {
  const { minValue, maxValue, value, onChange } = props;
  const tz = getTimezone();
  const wrappedOnChange = useCallback(
    (date: DateValue) => {
      const tz = getTimezone();
      onChange(date.toDate(tz));
    },
    [onChange],
  );
  return (
    <Calendar
      {...props}
      className={tisaSans.className}
      onChange={wrappedOnChange}
      value={value ? fromDate(value, tz) : undefined}
      minValue={minValue ? fromDate(minValue, tz) : undefined}
      maxValue={maxValue ? fromDate(maxValue, tz) : undefined}
    >
      <CalendarHeader />
      <CalendarGrid weekdayStyle="short">
        <CalendarGridHeader />
        <CalendarGridBody>
          {(date) => <CalendarCell date={date} className={cellStyles} />}
        </CalendarGridBody>
      </CalendarGrid>
    </Calendar>
  );
}

function CalendarHeader() {
  return (
    <header className="flex items-center gap-1 pb-4 px-1 w-full">
      <Button slot="previous">
        <BsChevronLeft aria-hidden />
      </Button>
      <Heading className="flex-1 font-semibold text-xl text-center mx-2 text-darkerGray" />
      <Button slot="next">
        <BsChevronRight aria-hidden />
      </Button>
    </header>
  );
}

function CalendarGridHeader() {
  return (
    <AriaCalendarGridHeader>
      {(day) => (
        <CalendarHeaderCell className="text-xs text-gray-dark font-semibold">
          {day}
        </CalendarHeaderCell>
      )}
    </AriaCalendarGridHeader>
  );
}
