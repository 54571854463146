import {
  combineDateAndTimeString,
  dateToDayjsTz,
  getDurationToNowInHours,
  getTimeDiffInHours,
  getTimeStringFromDate,
  getTimezone,
  setTimeForDate,
} from '@/utils/helper';
import { makeLogger } from '@/utils/makeLogger';
import { useMemo } from 'react';
import {
  PreferredStartWindow,
  PreferredStartWindowInput,
} from 'src/__generated__/graphql';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';
import { useQuery } from '@apollo/client';
import { gql } from 'src/__generated__';
import { throwIfUnauthenticatedCustomer } from '@/utils/narrow-utils';
import isDefined from '@/utils/isDefined';

dayjs.extend(utc);
dayjs.extend(tz);

export const CheckPriorityMarkup = gql(/* GraphQL */ `
  query CheckPriorityMarkup(
    $jobId: ID
    $preferredStartWindows: [PreferredStartWindowInput!]!
  ) {
    me {
      ... on Customer {
        id
        priorityMarkupForWindows(
          jobId: $jobId
          preferredStartWindows: $preferredStartWindows
        )
      }
    }
  }
`);

export type AvailabilityContent = {
  startDate?: Date;
  startTime?: string;
  endTime?: string;
};

const log = makeLogger('AvailabilityWindowPicker/utils');

// This util isn't specific to a single availability window picker, but is used
// when multiple pickers are used together to detect collisions between dates or other errors
export const useAvailabilityErrors = (
  availability?: Array<AvailabilityContent>,
  minDuration: number = 1,
) =>
  useMemo(() => {
    const overlappingAvailabilities = availability?.map((a, idx) => {
      if (!a.startDate || !a.endTime || !a.startTime) return false;
      // for each window, check if there's another window with the same date,
      // start time, and end time

      const aStart = combineDateAndTimeString(a.startDate, a.startTime);
      const aEnd = combineDateAndTimeString(a.startDate, a.endTime);

      return availability.some((b, i) => {
        if (i === idx) return false;
        if (!b.startDate || !b.endTime || !b.startTime) return false;

        const bStart = combineDateAndTimeString(b.startDate, b.startTime);
        const bEnd = combineDateAndTimeString(b.startDate, b.endTime);

        // If not the same day, they can't overlap
        if (!dayjs(aStart).isSame(bStart, 'day')) return false;

        /**
         * [-----------] A
         * [-----------] B
         *
         * identical windows
         * or identical starts/ends
         */
        if (a.startTime === b.startTime || a.endTime === b.endTime) return true;
        /**
         *  [-----------] A
         *     [-----------] B
         *
         *  b is later than a but overlapping
         */
        if (bStart > aStart && bStart < aEnd) return true;

        /**
         *       [-----------] A
         *  [-----------] B
         *
         *  b is earlier than a but overlapping
         */
        if (bEnd > aStart && bEnd < aEnd) return true;

        /**
         *  [-----------] A
         *    [-------] B
         *
         *  B is inside A
         */
        if (bStart > aStart && bEnd < aEnd) return true;

        /**
         *    [-------] A
         *  [-----------] B
         *
         *  A is inside B
         *  if b.start < a.start && b.end > a.end
         */
        if (bStart < aStart && bEnd > aEnd) return true;

        return false;
      });
    });

    return availability?.map(({ startTime, endTime, startDate }, idx) => {
      if (endTime && startTime && startDate) {
        if (overlappingAvailabilities?.[idx]) {
          log('overlapping error', availability, overlappingAvailabilities);
          return `Availabilities can't overlap.`;
        }

        const start = combineDateAndTimeString(startDate, startTime);
        const end = combineDateAndTimeString(startDate, endTime);
        const timeDiff = getTimeDiffInHours(start, end);

        if (timeDiff < minDuration) {
          if (timeDiff < 0) {
            return `Select an end time after the start time.`;
          }
          return `Select an availability window of at least ${minDuration} hour${
            minDuration > 1 ? 's' : ''
          } long.`;
        }

        const hour = startTime.split(':')[0];
        const minute = startTime.split(':')[1];
        if (
          getDurationToNowInHours(setTimeForDate(startDate, hour, minute)) <= 12
        ) {
          return 'Pick a time more than 12 hours from now.';
        }
      }
      return undefined;
    });
  }, [availability, minDuration]);

// eslint-disable-next-line no-unused-vars
export enum AvailabilityWarningType {
  // eslint-disable-next-line no-unused-vars
  PRIORITY = 'PRIORITY',
}

export const useAvailabilityWarnings = (
  availability?: Array<AvailabilityContent>,
  jobId?: string,
): Array<AvailabilityWarningType | undefined> => {
  const { data: _data } = useQuery(CheckPriorityMarkup, {
    skip: !availability?.length || availability.length === 0,
    variables: {
      jobId,
      preferredStartWindows: (availability || [])
        ?.map(availabilityToPreferredStartWindowInput)
        .filter(isDefined),
    },
  });

  return useMemo(() => {
    const data = throwIfUnauthenticatedCustomer(_data);
    const markups = data?.me?.priorityMarkupForWindows;
    const warnings = availability?.map((dt, i) => {
      const m = (markups || [])[i];
      if (m && m > 0) {
        return AvailabilityWarningType.PRIORITY;
      }
      return undefined;
    });
    return warnings as Array<AvailabilityWarningType | undefined>;
  }, [_data, availability]);
};

export const preferredStartWindowToAvailability = (
  window?: Omit<PreferredStartWindow, 'priorityMarkupPct'> | null,
): AvailabilityContent | undefined => {
  try {
    if (!window) return;
    const { startOfWindow, endOfWindow } = window;
    const startDate = new Date(startOfWindow);
    const startTime = getTimeStringFromDate(startDate);
    const endTime = getTimeStringFromDate(new Date(endOfWindow));
    return {
      startDate,
      startTime,
      endTime,
    };
  } catch (e) {
    log('error parsing preferred start window', e);
    return undefined;
  }
};

export const availabilityToPreferredStartWindow = (
  availability?: AvailabilityContent,
): Omit<PreferredStartWindow, 'priorityMarkupPct'> | undefined => {
  try {
    if (!availability) return;
    const { startDate, startTime, endTime } = availability;
    if (!startDate || !startTime || !endTime) return;
    const startOfWindow = dateToDayjsTz(
      combineDateAndTimeString(startDate, startTime),
    ).format();
    const endOfWindow = dateToDayjsTz(
      combineDateAndTimeString(startDate, endTime),
    ).format();
    return {
      startOfWindow,
      endOfWindow,
    };
  } catch (e) {
    log('error parsing availability', e);
    return undefined;
  }
};
export const availabilityToPreferredStartWindowInput = (
  availability?: AvailabilityContent,
): PreferredStartWindowInput | undefined => {
  const preferredStartWindow = availabilityToPreferredStartWindow(availability);
  if (
    !preferredStartWindow ||
    !preferredStartWindow.startOfWindow ||
    !preferredStartWindow.endOfWindow
  )
    return;
  return preferredStartWindow as PreferredStartWindowInput;
};

/**
 * Generates availabilities +3 days in the future
 * avoids weekends, markup days, and other taken days
 *
 *
 * ```
 * returns: {
 *  startDate: new Date('2023-01-31'),
 *  startTime: '08:00',
 *  endTime: '10:00',
 * }
 * ```
 */
export const getDefaultAvailabilities = ({
  numHours,
  numAvailabilitiesToGenerate = 3,
  takenAvailabilities,
  after,
}: {
  numHours?: number;
  numAvailabilitiesToGenerate: number;
  takenAvailabilities?: Array<AvailabilityContent>;
  after?: Date;
}): AvailabilityContent[] => {
  const tz = getTimezone();
  const firstDate = after
    ? dayjs(after).tz(tz).startOf('day').add(1, 'day')
    : dayjs().tz(tz).startOf('day').add(3, 'day').startOf('day');

  const times =
    !numHours || numHours <= 6
      ? {
          startTime: '10:00',
          endTime: '13:00',
        }
      : numHours < 10
      ? {
          startTime: '09:00',
          endTime: '12:00',
        }
      : {
          startTime: '08:00',
          endTime: '11:00',
        };

  // day we're incrementing
  let workingDate = firstDate;
  let availabilities = [];

  while (availabilities.length < numAvailabilitiesToGenerate) {
    // skip weekends
    if (workingDate.day() === 0 || workingDate.day() === 6) {
      workingDate = workingDate.add(1, 'day');
      continue;
    }

    // skip taken days
    if (
      takenAvailabilities?.some((dt) => {
        if (!dt.startDate) return false;
        const startDate = dateToDayjsTz(dt.startDate);
        return workingDate.isSame(startDate, 'day');
      })
    ) {
      workingDate = workingDate.add(1, 'day');
      continue;
    }

    // add availability for this day
    availabilities.push({
      startDate: workingDate.toDate(),
      ...times,
    });
    // increment day
    workingDate = workingDate.add(1, 'day');
  }
  return availabilities;
};
