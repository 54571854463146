import Datepicker from '@/components/ui/Datepicker/Datepicker';
import TimepickerSelect from '@/components/ui/Timepicker/TimepickerSelect';
import { useCallback } from 'react';
import { BsChevronDown } from 'react-icons/bs';
import { AvailabilityContent } from './utils';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(tz);

interface Props {
  labelPrefix?: string;
  errorMessage?: string;
  warningMessage?: string;
  availabilityWindow: AvailabilityContent;
  onWindowChange: (_window: AvailabilityContent) => void;
  minAvailabilityDate?: Date;
  label?: string;
  maxAvailabilityDate?: Date;
}

export function AvailabilityWindowPicker(props: Props) {
  const onAvailabilityDateChange = useCallback(
    (param: Date) => {
      props.onWindowChange({
        startDate: param,
        startTime: props.availabilityWindow.startTime,
        endTime: props.availabilityWindow.endTime,
      });
    },
    [props],
  );

  const onAvailabilityStartTimeChange = useCallback(
    (param?: string) => {
      props.onWindowChange({
        startDate: props.availabilityWindow.startDate,
        startTime: param,
        endTime: props.availabilityWindow.endTime,
      });
    },
    [props],
  );

  const onAvailabilityEndTimeChange = useCallback(
    (param?: string) => {
      props.onWindowChange({
        startDate: props.availabilityWindow.startDate,
        startTime: props.availabilityWindow.startTime,
        endTime: param,
      });
    },
    [props],
  );

  return (
    <div className="flex flex-1 flex-col">
      <div className="flex sm:flex-col lg:flex-row flex-1">
        <div className="flex-2">
          <Datepicker
            value={props.availabilityWindow.startDate}
            min={props.minAvailabilityDate}
            max={props.maxAvailabilityDate}
            onChange={onAvailabilityDateChange}
            label={props.label ? props.label : `${props.labelPrefix} Date`}
            visuallyHiddenLabel={!props.label}
          />
        </div>
        <div className="flex flex-1 flex-row sm:mt-4 lg:mt-0 sm:ml-0 lg:ml-3 items-center">
          <div className="flex flex-1">
            <TimepickerSelect
              icon={BsChevronDown}
              visuallyHiddenLabel
              value={props.availabilityWindow.startTime}
              onChange={onAvailabilityStartTimeChange}
              label={`${props.labelPrefix} start time`}
              min="07:00"
              max="18:30"
            />
          </div>
          <p className="sm:mx-1 md:mx-2">-</p>
          <div className="flex flex-1">
            <TimepickerSelect
              icon={BsChevronDown}
              visuallyHiddenLabel
              value={props.availabilityWindow.endTime}
              onChange={onAvailabilityEndTimeChange}
              label={`${props.labelPrefix} end time`}
              min="07:00"
            />
          </div>
        </div>
      </div>
      {props.errorMessage ? (
        <p className="mt-2 text-darkerRed">{props.errorMessage}</p>
      ) : props.warningMessage ? (
        <p className="mt-2 text-darkerRed">{props.warningMessage}</p>
      ) : null}
    </div>
  );
}
