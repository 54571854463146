import clsx from 'clsx';
import React, { useMemo } from 'react';

interface TimepickerProps {
  value?: string;
  // eslint-disable-next-line no-unused-vars
  onChange: (param: string | undefined) => void;
  label?: string;
  disabled?: boolean;
  min?: string;
  max?: string;
  visuallyHiddenLabel?: boolean;
  icon?: any;
  allowEmpty?: boolean;
  id?: string;
}

type TimeSlotOption = {
  label: string;
  value: string | '';
};

export const getTimeSlotOptions = (allowEmpty?: boolean) => {
  let slots: Array<TimeSlotOption> = allowEmpty
    ? [{ label: '', value: '' }]
    : [];
  let startHourSlot = 7;
  let slotIndex = 0;

  // eslint-disable-next-line no-constant-condition
  while (true) {
    let amSlot = 'AM';
    let hourSlot = startHourSlot + Math.floor(slotIndex / 2);

    if (hourSlot > 20 && slotIndex % 2 > 0) {
      return slots;
    }

    let minuteSlot = slotIndex % 2 === 0 ? '00' : '30';

    if (hourSlot >= 12) {
      if (hourSlot > 12) {
        hourSlot = hourSlot - 12;
      }
      amSlot = 'PM';
    }

    slots.push({
      label: `${
        hourSlot > 9 ? hourSlot : '0' + hourSlot
      }:${minuteSlot} ${amSlot}`,
      // value stays in 24-hour format, so for times with amSlot === 'PM', we add 12 to the hourSlot
      value: `${
        hourSlot > 9
          ? amSlot === 'PM' && hourSlot !== 12 // don't need to add 12h to 12 PM
            ? hourSlot + 12
            : hourSlot
          : amSlot === 'PM'
          ? hourSlot + 12
          : '0' + hourSlot
      }:${minuteSlot}`,
    });
    slotIndex++;
  }
};

const TimepickerSelect = (props: TimepickerProps) => {
  const {
    value = '',
    onChange,
    label,
    disabled = false,
    visuallyHiddenLabel,
    allowEmpty,
  } = props;

  let hourSlotItems: Array<TimeSlotOption> = useMemo(
    () => getTimeSlotOptions(allowEmpty),
    [allowEmpty],
  );

  // if there is a passed in id, use that. Otherwise, generate one from the label
  const id =
    props.id ||
    (label
      ? label.toLowerCase().replace(/ /g, '-') +
        '-' +
        Math.random().toString(16).slice(2)
      : undefined);

  return (
    <div className="flex flex-col w-full">
      {!visuallyHiddenLabel && label ? (
        <label className="font-bold" htmlFor={id}>
          {label}
        </label>
      ) : null}
      <select
        id={id}
        className={clsx(
          `self-start py-2 pr-2 pl-4 sm:border-r-4 md:border-r-8 md:px-2 rounded-lg border-0 bg-lightestGray h-[60px] min-w-[100px] hover:bg-lightGray focus:outline-purple focus:bg-white w-full ${
            disabled ? 'bg-lightestGray opacity-50 cursor-not-allowed' : ''
          }`,
          'focus:ring-1 focus:ring-purple',
        )}
        aria-label={visuallyHiddenLabel ? label : undefined}
        onChange={(e) => {
          onChange(e.target.value.length ? e.target.value : undefined);
        }}
        value={value}
        disabled={disabled}
        data-testid="timepicker-select"
        style={{ borderColor: 'transparent' }}
      >
        {hourSlotItems.map(({ label: slotLabel, value }, index) => (
          <option key={index} value={value}>
            {slotLabel}
          </option>
        ))}
      </select>
    </div>
  );
};

export default TimepickerSelect;
